import React, { useState } from 'react'
import SnackbarProvider from 'react-simple-snackbar'
import { useSnackbar } from 'react-simple-snackbar'
import { Container, Row, Col } from 'react-bootstrap'
import LayoutCustom from "../components/LayoutCustom";
import Footer from "../components/Footer";
import SEO from "../components/SEO";
import postFeedback from "../components/PostFeedback";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const $ = require("jquery");

const ContactUs = () => {
    return (
        <SnackbarProvider>
            <ContactUsHandler />
        </SnackbarProvider>
    )
}

class CustomDropdown extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var x, i, j, l, ll, selElmnt, a, b, c

        /* look for any elements with the class "custom-select" */
        x = document.getElementsByClassName("custom-select")
        l = x.length

        for (i = 0; i < l; i++) {
            selElmnt = x[i].getElementsByTagName("select")[0]
            ll = selElmnt.length

            /* for each element, create a new div that will act as the selected item */
            a = document.createElement("div")
            a.setAttribute("class", "select-selected app-selected-default")
            a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML
            x[i].appendChild(a)

            /* for each element, create a new div that will contain the option list */
            b = document.createElement("div")
            b.setAttribute("class", "select-items select-hide")

            for (j = 1; j < ll; j++) {
                /* for each option in the original select element, create a new div that will act as an option item */
                c = document.createElement("div")
                c.innerHTML = selElmnt.options[j].innerHTML

                c.addEventListener("click", function (e) {
                    /* when an item is clicked, update the original select box, and the selected item */
                    var y, i, k, s, h, sl, yl
                    s = this.parentNode.parentNode.getElementsByTagName("select")[0]
                    sl = s.length
                    h = this.parentNode.previousSibling

                    for (i = 0; i < sl; i++) {
                        if (s.options[i].innerHTML === this.innerHTML) {
                            s.selectedIndex = i
                            h.innerHTML = this.innerHTML
                            y = this.parentNode.getElementsByClassName("same-as-selected")
                            yl = y.length

                            for (k = 0; k < yl; k++) {
                                y[k].removeAttribute("class")
                            }

                            this.setAttribute("class", "same-as-selected")
                            break
                        }
                    }
                    h.click()
                })
                b.appendChild(c)
            }
            x[i].appendChild(b)

            a.addEventListener("click", function (e) {
                /* when the select box is clicked, close any other select boxes, and open/close the current select box:*/
                e.stopPropagation()
                closeAllSelect(this)
                this.nextSibling.classList.toggle("select-hide")
                this.classList.toggle("select-arrow-active")
                if (a.innerHTML === "I'm interested in…") {
                    this.classList.remove("app-selected-default");
                    this.classList.add("app-selected-default");
                } else {
                    this.classList.remove("app-selected-default");
                }
            })
        }

        function closeAllSelect(elmnt) {
            /* a function that will close all select boxes in the document, except the current select box */
            var x, y, i, xl, yl, arrNo = []
            x = document.getElementsByClassName("select-items")
            y = document.getElementsByClassName("select-selected")
            xl = x.length
            yl = y.length

            for (i = 0; i < yl; i++) {
                if (elmnt === y[i]) {
                    arrNo.push(i)
                } else {
                    y[i].classList.remove("select-arrow-active")
                }
            }

            for (i = 0; i < xl; i++) {
                if (arrNo.indexOf(i)) {
                    x[i].classList.add("select-hide")
                }
            }
        }

        /* if the user clicks anywhere outside the select box, then close all select boxes */
        document.addEventListener("click", closeAllSelect)
    }

    render() {
        return(
            <div className="custom-select">
                <select name="choice">
                    <option value="">I'm interested in…</option>
                    <option value="Appvity">Appvity</option>
                    <option value="eProducts">eProducts</option>
                    <option value="Services">Services</option>
                    <option value="Sales">Sales</option>
                    <option value="Solutions">Solutions</option>
                    <option value="Support">Support</option>
                    <option value="Technical concern">Technical concern</option>
                    <option value="Others">Others</option>
                </select>
            </div>
        )
    }
}

function clearFieldData() {
    return new Promise(resolve => {
        setTimeout(resolve, 1000);
    });
}

const initialState = {
    name: "",
    email: "",
    subject: "",
    choice: "",
    message: ""
};

const ContactUsHandler = () => {
    const [ { name, email, subject, message }, setState ] = useState(initialState);

    const clearState = () => {
        setState({ ...initialState });
        setStartDate();
    };

    const onChange = e => {
    const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const [ openSnackbar, closeSnackbar ] = useSnackbar()
    const [ nameStatus, setNameStatus ] = useState("name")
    const [ emailStatus, setEmailStatus ] = useState("email")
    const [ choiceStatus, setChoiceStatus ] = useState("")
    const [ dateValidation, setDateValidation ] = useState()

    const [ startDate, setStartDate ] = useState();
    const [ errors, setErrors ] = useState({});

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('/');
    }

    // method to validate values 
    const handleValidation = (nameStatus, name, emailStatus, email, subject, choiceStatus, message, dateValidation) => {
        let errors = {};
        let formIsValid = true;

        // name check if name is empty or not
        if (!name) {
            formIsValid = false;
            errors[nameStatus] = "Please enter your name.";
        } 
        // else {
        //     // name should not contain special char
        //     if (typeof name !== "undefined") {
        //         if(name.match(/[&\/\\#,+()$~%.'":*?<>{}]/g)) {
        //             formIsValid = false;
        //             errors[nameStatus] = "You have entered an invalid name with special character. Please try again.";
        //         }
        //     }
        // }
        setNameStatus(nameStatus)

        // email should not be empty
        if (!email) {
            formIsValid = false;
            errors[emailStatus] = "Please enter your email.";
        } else {
            // validating email
            if (typeof email !== "undefined") {
                let lastAtPos = email.lastIndexOf('@');
                let lastDotPos = email.lastIndexOf('.');

                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                    formIsValid = false;
                    errors[emailStatus] = "You have entered an invalid email address. Please try again.";
                }
            }
        }
        setEmailStatus(emailStatus)

        //because dropdown custom is select option fake so can't pass data normally
        const choice = document.querySelector('.app-section-contactus .app-contactus-detail .app-contactus-left .custom-select .select-selected').innerHTML;
        const choiceVal = (choice !== "I'm interested in…") ? choice : "";
        choiceStatus = choiceVal;
        setChoiceStatus(choiceStatus)

        if (choiceStatus === "") {
            formIsValid = false;
            errors[choiceStatus] = "Please select an option."
        }

        if (startDate) {
            if (formatDate(yesterday) !== formatDate(startDate)) {
                formIsValid = false;
                errors[dateValidation] = "Date validation is not correct. Please enter yesterday's date again."
            }
        } else {
            formIsValid = false;
            errors[dateValidation] = "Please enter yesterday's date."
        }
        setDateValidation(dateValidation);

        if (formIsValid === true) {
            // fetch('http://localhost:3000/contact-us', {
            //     method: 'POST',
            //     headers: {
            //         "Content-Type": "application/json"
            //     },
            //     body: JSON.stringify({
            //         "name": name,
            //         "email": email,
            //         "subject": subject,
            //         "choice": choiceVal,
            //         "message": message,
            //     })
            // })
            // .then(res => res.json())
            // .then(res => {
            //     openSnackbar("We have received your message. Thank you for contacting us.")
            // })
            // .catch((error) => {
            //     openSnackbar("Send mail failed. Please try again !!!")
            // });

            var data = {};
            data["type"] = "contact";
            data["name"] = name;
            data["from"] = email;
            data["subject"] = subject;
            data["topic"] = choiceVal;
            message = message.replace(/\n/gi, "<br/>");
            data["message"] = message;
            
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                openSnackbar("We have received your message. Thank you for contacting us.")
                clearFieldData().then(clearState());
                $(".app-section-contactus .app-contactus-detail .app-contactus-left .custom-select .select-selected").html("I'm interested in…");
                $(".app-section-contactus .app-contactus-detail .app-contactus-left .custom-select .select-selected").addClass("app-selected-default");
            },
            function(error) {
                openSnackbar("Send mail failed. Please try again !!!")
            });
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = e => {
        e.preventDefault();
        handleValidation(nameStatus, name, emailStatus, email, subject, choiceStatus, message, dateValidation);
    }

	return (
        <LayoutCustom>
            <SEO title="Contact Us" description="Contact Appvity if you have any question about eProducts, services, or product payment." meta={[{ name: `keywords`, content: ["Appvity contact us", "question about Appvity products", "question about eProducts"] }]} pathname="/contact-us" />
            <div className="main-content-padding">
                <div className="app-general-seciton app-section-contactus" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-title">
                            <h2>Contact Us</h2>
                        </div>
                        <div className="app-contactus-detail">
                            <Row>
                                <Col xs={12} md={12} lg={5}>
                                    <form onSubmit={handleSubmit} noValidate>
                                        <div className="app-contactus-left">
                                            <input 
                                                type="text" 
                                                placeholder="Name" 
                                                name="name" 
                                                value={name} 
                                                onChange={onChange} 
                                                required 
                                            />
                                            <h6 className="app-contact-us-errors-label">{errors[nameStatus]}</h6>
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                value={email} 
                                                onChange={onChange} 
                                                required
                                            />
                                            <h6 className="app-contact-us-errors-label">{errors[emailStatus]}</h6>
                                            <input
                                                type="text"
                                                placeholder="Subject"
                                                name="subject"
                                                value={subject} 
                                                onChange={onChange} 
                                            />
                                            <CustomDropdown name="choice" />
                                            <h6 className="app-contact-us-errors-label">{errors[choiceStatus]}</h6>
                                            <textarea
                                                rows="6"
                                                placeholder="Message"
                                                name="message"
                                                value={message} 
                                                onChange={onChange} 
                                            ></textarea>
                                            <label className="app-contact-us-label-validation">Please enter yesterday's date for validation (MM/DD/YYYY)</label>
                                            <DatePicker 
                                                selected={startDate} 
                                                onChange={date => setStartDate(date)} 
                                            />
                                            <h6 className="app-contact-us-errors-label">{errors[dateValidation]}</h6>
                                            <input
                                                className="app-contactus-btn"
                                                type="submit"
                                                value="Send Message"
                                            />
                                        </div>
                                    </form>
                                </Col>
                                <Col xs={12} md={12} lg={7}></Col>
                            </Row>
                        </div>
                    </Container>
                    <div className="footer-custom">
                        <Footer />
                    </div>
                </div>
            </div>
        </LayoutCustom>
	)
}

export default ContactUs